@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Mona-Sans', sans-serif;
    font-weight: 400;
    color: #253858;
  }

  /* For Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
    /* background-color: #F5F8FE; */
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* Set the background color of the scrollbar track */
    border-radius: 4px; /* Set the border-radius of the scrollbar track */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #E9ECF2; /* Set the color of the scrollbar thumb */
    border-radius: 10px; /* Set the border-radius of the scrollbar thumb */
    background: "#E9ECF2";
    border: "1px solid #E9ECF2";
    transform: "matrix(-1, 0, 0, 1, 0, 0)";
    box-shadow: "1px 1px 2px rgba(0, 0, 0, 0.15)";
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #E9ECF2; /* Set the color of the scrollbar thumb on hover */
  }
  
  .no-visible-bar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    /* background-color: #F5F8FE; */
  }
}

.rdp-root {
  position: relative;
}

table.rdp-month_grid {
  align-items: center;
  margin: 0 auto;
}

.rdp-month_caption {
  text-align: center;
}

.rdp-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 12px;
  right: 12px;
  top: 4px;
}

.rdp-outside {
  color: #79808A;
}

.rdp-button_next, .rdp-button_previous {
  color: #79808A;
}

.rdp-button_next svg, .rdp-button_previous svg {
  width: 16px !important;
  height: 16px !important;
}

.rdp-range_middle {
  background-color: #E7F1FF !important;
  border-radius: 0 !important;
  color: #253858 !important;
}

.rdp-range_start {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rdp-range_end {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rdp-range_end.rdp-range_start {
  border-radius: 12px !important;
}

/* css for the text editor */
.editor-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  background: #f8f9fa;
}

.w-md-editor {
  border: none !important;
  background: white;
  border-radius: 8px;
}

.w-md-editor-toolbar {
  background: #f1f3f5;
  border-bottom: 1px solid #ddd;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}


input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
